import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// import language settings
import translation_en from './en.json'
import translation_ja from './ja.json'

const resources = {
  ja: {
    translation: translation_ja
  },
  en: {
    translation: translation_en
  }
}

// i18n.languagesはlngとfallbackLngを返すため代わりにこちらを使うこと
export const languages = ['en', 'ja']

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: languages,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  })

export default i18n
