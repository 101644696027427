exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-games-automaton-tsx": () => import("./../../../src/pages/games/automaton.tsx" /* webpackChunkName: "component---src-pages-games-automaton-tsx" */),
  "component---src-pages-games-eca-tsx": () => import("./../../../src/pages/games/eca.tsx" /* webpackChunkName: "component---src-pages-games-eca-tsx" */),
  "component---src-pages-games-gameoflife-tsx": () => import("./../../../src/pages/games/gameoflife.tsx" /* webpackChunkName: "component---src-pages-games-gameoflife-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-games-minesweeper-tsx": () => import("./../../../src/pages/games/minesweeper.tsx" /* webpackChunkName: "component---src-pages-games-minesweeper-tsx" */),
  "component---src-pages-games-pac-boy-tsx": () => import("./../../../src/pages/games/pac-boy.tsx" /* webpackChunkName: "component---src-pages-games-pac-boy-tsx" */),
  "component---src-pages-games-tetrimimus-tsx": () => import("./../../../src/pages/games/tetrimimus.tsx" /* webpackChunkName: "component---src-pages-games-tetrimimus-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-tools-coding-index-tsx": () => import("./../../../src/pages/tools/coding/index.tsx" /* webpackChunkName: "component---src-pages-tools-coding-index-tsx" */),
  "component---src-pages-tools-coding-scheme-tsx": () => import("./../../../src/pages/tools/coding/scheme.tsx" /* webpackChunkName: "component---src-pages-tools-coding-scheme-tsx" */),
  "component---src-pages-tools-geo-geocode-tsx": () => import("./../../../src/pages/tools/geo/geocode.tsx" /* webpackChunkName: "component---src-pages-tools-geo-geocode-tsx" */),
  "component---src-pages-tools-geo-index-tsx": () => import("./../../../src/pages/tools/geo/index.tsx" /* webpackChunkName: "component---src-pages-tools-geo-index-tsx" */),
  "component---src-pages-tools-geo-shp-geojson-tsx": () => import("./../../../src/pages/tools/geo/shp_geojson.tsx" /* webpackChunkName: "component---src-pages-tools-geo-shp-geojson-tsx" */),
  "component---src-pages-tools-geo-wkt-geojson-tsx": () => import("./../../../src/pages/tools/geo/wkt_geojson.tsx" /* webpackChunkName: "component---src-pages-tools-geo-wkt-geojson-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-pages-tools-math-decimal-tsx": () => import("./../../../src/pages/tools/math/decimal.tsx" /* webpackChunkName: "component---src-pages-tools-math-decimal-tsx" */),
  "component---src-pages-tools-math-index-tsx": () => import("./../../../src/pages/tools/math/index.tsx" /* webpackChunkName: "component---src-pages-tools-math-index-tsx" */),
  "component---src-pages-tools-math-tex-tsx": () => import("./../../../src/pages/tools/math/tex.tsx" /* webpackChunkName: "component---src-pages-tools-math-tex-tsx" */),
  "component---src-pages-tools-network-index-tsx": () => import("./../../../src/pages/tools/network/index.tsx" /* webpackChunkName: "component---src-pages-tools-network-index-tsx" */),
  "component---src-pages-tools-network-ip-location-tsx": () => import("./../../../src/pages/tools/network/ip_location.tsx" /* webpackChunkName: "component---src-pages-tools-network-ip-location-tsx" */),
  "component---src-pages-tools-security-file-scanner-tsx": () => import("./../../../src/pages/tools/security/file_scanner.tsx" /* webpackChunkName: "component---src-pages-tools-security-file-scanner-tsx" */),
  "component---src-pages-tools-security-hash-tsx": () => import("./../../../src/pages/tools/security/hash.tsx" /* webpackChunkName: "component---src-pages-tools-security-hash-tsx" */),
  "component---src-pages-tools-security-index-tsx": () => import("./../../../src/pages/tools/security/index.tsx" /* webpackChunkName: "component---src-pages-tools-security-index-tsx" */),
  "component---src-pages-tools-text-index-tsx": () => import("./../../../src/pages/tools/text/index.tsx" /* webpackChunkName: "component---src-pages-tools-text-index-tsx" */),
  "component---src-pages-tools-text-json-diff-tsx": () => import("./../../../src/pages/tools/text/json_diff.tsx" /* webpackChunkName: "component---src-pages-tools-text-json-diff-tsx" */),
  "component---src-pages-tools-text-json-tsx": () => import("./../../../src/pages/tools/text/json.tsx" /* webpackChunkName: "component---src-pages-tools-text-json-tsx" */),
  "component---src-pages-tools-text-uri-tsx": () => import("./../../../src/pages/tools/text/uri.tsx" /* webpackChunkName: "component---src-pages-tools-text-uri-tsx" */),
  "component---src-pages-tools-text-xml-tsx": () => import("./../../../src/pages/tools/text/xml.tsx" /* webpackChunkName: "component---src-pages-tools-text-xml-tsx" */),
  "component---src-pages-tools-web-index-tsx": () => import("./../../../src/pages/tools/web/index.tsx" /* webpackChunkName: "component---src-pages-tools-web-index-tsx" */),
  "component---src-pages-tools-web-seo-tsx": () => import("./../../../src/pages/tools/web/seo.tsx" /* webpackChunkName: "component---src-pages-tools-web-seo-tsx" */)
}

