import React from 'react'
import { useTranslation } from 'react-i18next'

export interface PageContext {
  lang: string
  originalPath: string
}

interface Props {
  value: PageContext
  children: React.ReactNode
}

const PageContext = React.createContext({
  lang: 'en',
  originalPath: ''
})

export const PageContextProvider = ({ value, children }: Props) => {
  const { i18n } = useTranslation()

  /* このコードだとコード生成時に全ての言語がデフォルト言語になるので使用しないこと
  React.useEffect(() => {
    i18n.changeLanguage(value.lang)
  }, [])
  */

  if (i18n.language !== value.lang) {
    i18n.changeLanguage(value.lang)
  }

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
