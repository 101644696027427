import React from 'react'
import type {
  GatsbySSR,
  RenderBodyArgs,
  WrapPageElementBrowserArgs
} from 'gatsby'
import { I18nextProvider } from 'react-i18next'
import i18n from './src/i18n/configs'
import { type PageContext, PageContextProvider } from './src/i18n/PageContext'

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({
  element,
  props
}: WrapPageElementBrowserArgs<object, PageContext>) => {
  return (
    <PageContextProvider value={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}

const headComponents = [
  <link
    key={0}
    href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700&display=swap"
    rel="stylesheet"
    crossOrigin="anonymous"
  />,
  <link
    key={1}
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@100;200;300;400;500;600;700&display=swap"
    rel="stylesheet"
    crossOrigin="anonymous"
  />,
  <link
    key={3}
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css"
    integrity="sha384-n8MVd4RsNIU0tAv4ct0nTaAbDJwPJzDEaqSD1odI+WdtXRGWt2kTvGFasHpSy3SV"
    crossOrigin="anonymous"
  />,
  <link
    key={5}
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/combine/npm/prismjs@1/themes/prism.min.css"
    crossOrigin="anonymous"
  />
]

export const onRenderBody = ({ setHeadComponents }: RenderBodyArgs) => {
  setHeadComponents(headComponents)
}
